<template>
    <div
        data-scroll
        :data-scroll-id="name"
        @click.self="changeItemHandler"
        @mouseenter.self="onMouseEnter"
        @mousemove.self="onMouseEnter"
        @mouseleave.self="onMouseLeave"
        @mouseout.self="onMouseLeave"
        @mouseup.self="onMouseLeave"
        :style="wineColor"
        class="wine-variety"
    >
        <div class="nav-buttons">
            <button class="prev" @click="changeActiveItem(-1, true)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42.172"
                    height="46.857"
                    viewBox="0 0 42.172 46.857"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(40.052 44.736) rotate(180)"
                    >
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            x2="19.005"
                            y2="19.881"
                            transform="translate(18.926 0)"
                            fill="none"
                            stroke="#e5b676"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                        <line
                            id="Line_4"
                            data-name="Line 4"
                            x1="18.927"
                            y2="19.881"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#e5b676"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            y2="40.458"
                            transform="translate(18.964 2.779)"
                            fill="none"
                            stroke="#e5b676"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                    </g>
                </svg>
            </button>
            <button class="next" @click="changeActiveItem(1, true)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42.172"
                    height="46.857"
                    viewBox="0 0 42.172 46.857"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(2.121 2.121)"
                    >
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            x2="19.005"
                            y2="19.881"
                            transform="translate(18.926 0)"
                            fill="none"
                            stroke="#e5b676"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                        <line
                            id="Line_4"
                            data-name="Line 4"
                            x1="18.927"
                            y2="19.881"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#e5b676"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            y2="40.458"
                            transform="translate(18.964 2.779)"
                            fill="none"
                            stroke="#e5b676"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                    </g>
                </svg>
            </button>
        </div>
        <img
            src="@/assets/images/wine-collection-bg.png"
            class="background-image"
            alt=""
        />
        <div class="wine-main-content">
            <div v-observer class="variety-list">
                <mouse-changer :type="mouseTypes.Default">
                    <div class="variety-list-contain">
                        <div
                            v-for="(item, index) in wineCollection"
                            :key="index"
                            :class="{ active: item.active }"
                            @click="changeActiveItem(index)"
                            class="variety-item"
                        >
                            <div class="dot-container">
                                <div class="line"></div>
                                <div class="dot"></div>
                            </div>
                            <div class="title-container">
                                <span class="title">{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                </mouse-changer>
            </div>
            <div v-observer id="wine-description" class="wine-description">
                <div
                    :class="{ animated: clickedItem === 'prev' }"
                    class="prev-content out-cont"
                >
                    <img
                        :src="prevItem.image"
                        @click="changeActiveItem(-1, true)"
                        class="image-main"
                        alt=""
                    />
                </div>
                <div
                    :class="{ animated: clickedItem === 'next' }"
                    class="next-content out-cont"
                >
                    <img
                        :src="nextItem.image"
                        @click="changeActiveItem(1, true)"
                        class="image-main"
                        alt=""
                    />
                </div>
                <div
                    class="bottle-image-container"
                    :class="{ animated: clickedItem }"
                >
                    <mouse-changer :type="mouseTypes.ImageScale">
                        <img
                            :src="activeListItem.image"
                            @click="itemClick($event)"
                            :id="clickedTargetRef"
                            class="bottle-image"
                            alt=""
                        />
                    </mouse-changer>
                </div>
                <mouse-changer :type="mouseTypes.Default">
                    <div class="wine-about">
                        <h2 class="brand">{{ activeListItem.brand }}</h2>
                        <h3 class="name">{{ activeListItem.name }}</h3>
                        <div class="description-container">
                            <div ref="showMore" class="description-list">
                                <div class="list-content">
                                    <div
                                        class="list-item"
                                        v-for="(item,
                                        index) in activeListItem.info"
                                        :key="index"
                                    >
                                        <span class="section-name">{{
                                            item.title
                                        }}</span>
                                        <div
                                            class="list-content"
                                            v-html="item.content"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="show-more-wrapper">
                                <div
                                    @click="toggle"
                                    class="show-more"
                                    v-if="activeListItem.info.length > 1"
                                >
                                    {{ toggleText }}
                                </div>
                                <a
                                    v-if="
                                        get(activeListItem, 'link') &&
                                            includes(
                                                get(activeListItem, 'link'),
                                                'http'
                                            )
                                    "
                                    :href="get(activeListItem, 'link')"
                                    class="show-more"
                                >
                                    {{ _tr("Specificatioons") }}
                                </a>
                                <router-link
                                    v-else-if="
                                        get(activeListItem, 'link') &&
                                            !includes(
                                                get(activeListItem, 'link'),
                                                'http'
                                            )
                                    "
                                    :to="get(activeListItem, 'link')"
                                    class="show-more"
                                >
                                    {{ _tr("Specificatioons") }}
                                </router-link>
                            </div>
                        </div>
                        <div
                            v-if="activeListItem.audio"
                            class="audio-container"
                        >
                            <span class="section-name">Music Composition:</span>
                            <div class="music-about-content">
                                <span class="title description">{{
                                    activeListItem.music_title
                                }}</span>
                                <span class="title description">{{
                                    activeListItem.music_teaser
                                }}</span>
                            </div>
                            <div id="waveform"></div>
                            <div class="control-container">
                                <div
                                    @click="start"
                                    :class="{ active: played }"
                                    class="play-container control"
                                >
                                    <span class="title">Play</span>
                                    <img
                                        src="@/assets/images/play.svg"
                                        alt=""
                                    />
                                </div>
                                <div
                                    @click="pause"
                                    :class="{ active: !played }"
                                    class="pause-container control"
                                >
                                    <span class="title">Pause</span>
                                    <img
                                        src="@/assets/images/stop.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="award-cont">
                            <img
                                v-for="(aw, index) in activeListItem.awards"
                                :key="index"
                                :src="aw.url"
                                :style="
                                    `z-index: ${activeListItem.awards.length -
                                        index}`
                                "
                            />
                        </div>
                    </div>
                </mouse-changer>
            </div>
        </div>
    </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import { clamp } from "@/scripts/utils";
import { mapActions, mapGetters } from "vuex";
import ImageScale from "@/mixins/ImageScale";
import MouseChanger from "@/components/custom/MouseChanger";
import last from "lodash/last";
import get from "lodash/get";
import includes from "lodash/includes";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: [Object, Array],
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    mixins: [ImageScale],
    data() {
        return {
            wavesurfer: null,
            played: false,
            activeSlideIndex: 0,
            activeSlideIndexForRoute: 0,
            toggleIndex: 0,
            clickedItem: null,
            imageScaleHeightViewPort: true,
            sliderTimeOut: null,
            load: false,
            get,
            includes
        };
    },
    components: { MouseChanger },
    computed: {
        ...mapGetters({
            device: "resize/geDevice",
            scroll: "scroll/getScroll"
        }),
        activeListItem() {
            return this.wineCollection?.find(item => item.active) || {};
        },
        prevItem() {
            let prevItemIndex = clamp(
                this.activeSlideIndex - 1,
                0,
                this.wineCollection.length - 1
            );

            return (
                this.wineCollection?.find(
                    (item, index) => index === prevItemIndex
                ) || {}
            );
        },
        prevCloneItem() {
            let prevItemIndex = clamp(
                this.activeSlideIndexForRoute - 1,
                0,
                this.wineCollection.length - 1
            );

            return (
                this.wineCollection?.find(
                    (item, index) => index === prevItemIndex
                ) || {}
            );
        },
        nextItem() {
            let nextItemIndex = clamp(
                this.activeSlideIndex + 1,
                0,
                this.wineCollection.length - 1
            );

            return (
                this.wineCollection?.find(
                    (item, index) => index === nextItemIndex
                ) || {}
            );
        },
        nextCloneItem() {
            let nextItemIndex = clamp(
                this.activeSlideIndexForRoute + 1,
                0,
                this.wineCollection.length - 1
            );

            return (
                this.wineCollection?.find(
                    (item, index) => index === nextItemIndex
                ) || {}
            );
        },
        wineColor() {
            return {
                "--brand-color": this.activeListItem?.color
            };
        },
        wineCollection() {
            return this.data?.list?.map((item, index) => ({
                info: item.info?.tabs,
                teaser: item.info?.teaser,
                color: item.color,
                active_color: item.active_color,
                audio_color: item.nonactive_music_color,
                title: item.title,
                image: item.image?.[0]?.devices?.[this.device?.type],
                awards: item.awards,
                audio: item.music_audio?.videoFile?.url,
                music_title: item.music_audio?.title,
                music_teaser: item.music_audio?.teaser,
                brand: item.info?.title,
                name: item.info?.teaser,
                id: item.id,
                slug: item.slug,
                link: item?.link,
                active: index === this.activeSlideIndex,
                index,
                secondType: true
            }));
        },
        toggleText() {
            return this.toggleIndex % 2 === 0
                ? this._tr("show more")
                : this._tr("show Less");
        },
        cloneHeight() {
            let height = 596;
            if (this.device?.size < 1441) {
                height = 450;
            }
            if (this.device?.size < 1366) {
                height = 400;
            }
            if (this.device?.size < 1024) {
                height = 250;
            }

            return height;
        },
        clickedTargetRef() {
            return `wine-bottle${this.activeListItem?.id}`;
        },
        images() {
            return this.wineCollection.map(item => item.image);
        },
        isInner() {
            return this.config?.conf?.includes("inner");
        }
    },
    methods: {
        ...mapActions({
            setMouseType: "mouseCursor/setMouseType",
            setColor: "mouseCursor/setColor"
        }),
        changeItemHandler(ev) {
            ev.clientX >= innerWidth / 2
                ? this.changeActiveItem(1, true)
                : this.changeActiveItem(-1, true);
        },
        changeActiveItem(direction, menuControl = false, immediate = true) {
            if (!this.load) return;
            if (this.sliderTimeOut) clearTimeout(this.sliderTimeOut);
            this.pause();
            let dom = document.getElementById("wine-description");
            dom.classList.remove("observed");
            this.toggleIndex = 0;
            this.sliderTimeOut = setTimeout(() => {
                let prvActiveItemIndex = this.wineCollection?.findIndex(
                    item => item.active
                );
                this.activeSlideIndex = "";

                if (menuControl) {
                    let index = clamp(
                        prvActiveItemIndex + direction,
                        0,
                        this.wineCollection.length - 1
                    );
                    this.activeSlideIndex = index;

                    if (immediate) this.activeSlideIndexForRoute = index;
                } else {
                    this.activeSlideIndex = direction;
                    if (immediate) this.activeSlideIndexForRoute = direction;
                }
                this.changeRouteInInner();
                setTimeout(() => {
                    this.loadAudio(this.wineCollection[this.activeSlideIndex]);
                    this.setInfoHeight();
                }, 10);
                dom.classList.add("observed");
            }, 800);
        },
        start() {
            this.played = true;
            this.wavesurfer.play();
        },
        pause() {
            this.wavesurfer?.pause();
            this.played = false;
        },
        setInfoHeight() {
            if (this.activeListItem.info.length < 1) {
                return;
            }
            let height = document
                .querySelectorAll(".wine-about .list-item")?.[0]
                ?.getBoundingClientRect()?.height;

            try {
                this.$refs.showMore.style.maxHeight = `${height}px`;
            } catch (e) {
                console.log(e);
            }
        },
        toggle() {
            if (this.activeListItem.info.length < 1) {
                return;
            }
            let maxHeight = document
                .querySelector(".wine-about .list-content")
                ?.getBoundingClientRect()?.height;
            let minHeight = document
                .querySelectorAll(".wine-about .list-item")?.[0]
                ?.getBoundingClientRect()?.height;

            let height = this.toggleIndex % 2 === 0 ? maxHeight : minHeight;

            try {
                this.$refs.showMore.style.maxHeight = `${height}px`;
                setTimeout(() => {
                    this.scroll.update();
                }, 50);
            } catch (e) {
                console.log(e);
            }

            this.toggleIndex++;
        },
        loadAudio(item) {
            const wave = document.querySelector("wave");
            wave?.remove();

            if (!this.activeListItem?.audio) return;
            this.wavesurfer = WaveSurfer.create({
                container: "#waveform",
                waveColor: this.activeListItem?.active_color,
                progressColor: this.activeListItem?.audio_color,
                barHeight: "0.8",
                height: 80
            });

            this.wavesurfer.load(item.audio);
        },
        onMouseEnter() {
            this.setMouseType(this.mouseTypes.WineCollection);
            this.setColor(this.activeListItem?.color);
        },
        onMouseLeave() {
            this.setMouseType(this.mouseTypes.Default);
        },
        preloadImg(src, callback) {
            let img = new Image();
            img.onload = callback;
            img.src = src;
        },
        preloadImages(imgs, callback) {
            let imagesToLoad = imgs.length;
            let _f = function() {
                if (imagesToLoad > 0) return;
                callback();
            };
            for (let i = 0, l = imgs.length; i < l; i++)
                this.preloadImg(imgs[0], function() {
                    imagesToLoad--;
                    _f();
                });
        },
        setLoad() {
            this.load = true;
        },
        setActiveItemForInner() {
            if (!this.isInner) return;
            let id = last(this.$route?.path?.split("/"));
            let activeIndex = this.wineCollection?.findIndex(
                item => item.title?.toLowerCase() === id
            );
            this.activeSlideIndex = activeIndex > 0 ? activeIndex : 0;
            this.activeSlideIndexForRoute = activeIndex > 0 ? activeIndex : 0;
        },
        changeRouteInInner() {
            if (!this.isInner) return;

            let rout = {
                ...this.$route
            };
            let params = rout?.path?.split("/");
            params?.pop();
            let path = params?.join("/");
            history.pushState({}, null, path + "/" + this.activeListItem?.slug);
        }
    },
    created() {
        this.setActiveItemForInner();
        this.preloadImages(this.images, this.setLoad);
    },
    mounted() {
        this.$nextTick(() => {
            this.loadAudio(this.wineCollection[0]);
            setTimeout(() => {
                this.setInfoHeight();
            }, 600);
        });
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.04, 0.29, 0.35, 1);

.wine-variety /deep/ {
    .award-cont {
        margin-top: 20px;
        display: flex;
        img {
            position: relative;
            width: 80px;
            transform: translateX(-45px);
            &:first-child {
                transform: translateX(0px);
                width: 100px;
            }
        }
    }
    .nav-buttons {
        display: none;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 50px);
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        top: 150px;
        margin-left: auto;
        margin-right: auto;
        button {
            width: 60px;
            height: 60px;
            border: none;
            border-radius: 50%;
            outline: none;
            background-color: #740b43;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(90deg);
            svg {
                transform: scale(0.6);
            }
        }
        @media (max-width: 767px) {
            display: flex;
        }
    }
    display: flex;
    justify-content: center;
    margin: 144px 0;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        margin: 89px 0;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        margin: 89px 0;
    }

    @media only screen and (max-width: 1023px) {
        padding: 0 30px;
        margin: 89px 0;
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        margin: 110px 0;
        overflow: unset;
    }

    .background-image {
        position: absolute;
        width: 100%;
        opacity: 0.03;
        left: 0;
        pointer-events: none;
        top: 0;
    }

    .wine-main-content {
        display: flex;
        pointer-events: none;
        max-width: 1200px;
        width: 100%;
        @media only screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .variety-list {
        width: 50%;
        pointer-events: none;
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            width: 40%;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: fit-content;
            align-items: flex-start;
        }
        @media only screen and (max-width: 767px) {
            flex-direction: unset;
            max-width: 100%;
            margin-top: 0;
            width: 100%;
            position: absolute;
            padding: 0 16px;
            top: -55px;
        }

        .title {
            font-size: 16px;
            letter-spacing: 1.3px;
            color: black;
            cursor: pointer;
            transform: translateY(100%);
            display: block;
            position: relative;
            transition: transform 0.8s $ease-out;
            // white-space: nowrap;
            text-align: left;
            @media only screen and (max-width: 1023px) {
                font-size: 14px;
            }
        }

        .variety-list-contain {
            padding-top: 173px;
            padding-right: 270px;
            padding-bottom: 100px;
            pointer-events: auto;
            display: flex;
            flex-direction: column;

            &::-webkit-scrollbar {
                display: none;
            }

            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                padding-right: 150px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                padding-right: 170px;
            }
            @media only screen and (max-width: 767px) {
                flex-direction: unset;
                padding: 0;
                overflow: auto;
                align-items: center;
            }
        }

        .variety-item {
            display: flex;
            align-items: center;
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
                margin-left: 0;
            }

            @media only screen and (max-width: 767px) {
                margin-left: 12px;
                margin-top: 0;
            }

            .dot-container {
                display: flex;
                align-items: center;
                @media only screen and (max-width: 767px) {
                    display: none;
                }

                .line {
                    width: 120px;
                    height: 1px;
                    background: var(--brand-color);
                    opacity: 0;
                    transition: all 0.8s 0.2s $ease-out;
                    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                        width: 50px;
                    }
                    @media only screen and (max-width: 1023px) {
                        width: 10px;
                    }
                }

                .dot {
                    @include circle(10);
                    background: var(--brand-color);
                    opacity: 0;
                    margin: 0 10px;
                    transition: all 0.8s 0.2s $ease-out;
                }
            }

            .title-container {
                position: relative;
                overflow: hidden;
            }

            .title {
                @media only screen and (max-width: 767px) {
                    padding-bottom: 5px;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                    text-decoration-color: transparent;
                    transition: all 0.8s $ease-out;
                }
            }
        }

        &.observed {
            .title {
                transform: translateY(0);
                transition: all 0.8s 0.2s $ease-out;
                @media (max-width: 767px) {
                    white-space: nowrap;
                }
            }

            .variety-item {
                &.active {
                    .dot-container {
                        opacity: 1;
                        transition: all 0.8s 0.2s $ease-out;

                        .line,
                        .dot {
                            opacity: 1;
                        }
                    }

                    .title {
                        color: var(--brand-color);
                        transition: all 0.8s 0.2s $ease-out;
                        @media only screen and (max-width: 767px) {
                            text-decoration-color: var(--brand-color);
                            transition: all 0.8s 0.2s $ease-out;
                        }
                    }
                }
            }
        }
    }

    .wine-description {
        width: 50%;
        display: flex;
        text-align: left;
        pointer-events: auto;
        @media only screen and (max-width: 767px) {
            display: block;
            width: 100%;
        }
        .show-more-wrapper {
            display: flex;
        }
        .show-more {
            &:nth-child(1) {
                margin-right: 15px;
            }
        }
        &.observed {
            .image-main {
                opacity: 1;
                transition: all 0.8s 0.2s $ease-out !important;
            }

            .out-cont {
                will-change: transform;
                transform: translate(0, -50%);
                transition: all 0.8s 0.2s $ease-out !important;
            }

            .bottle-image-container {
                opacity: 1;
                transform: scale(1);
                transition: all 0.8s 0.2s $ease-out !important;
            }

            .wine-about {
                transform: translateY(0) translateX(-100px);
                @media only screen and (max-width: 1440px) and (min-width: 768px) {
                    transform: translateY(0) translateX(-60px);
                }
                @media only screen and (max-width: 767px) {
                    transform: unset;
                }

                .brand,
                .name {
                    opacity: 1;
                    transition: all 0.8s 0.2s $ease-out;
                }

                .description-container {
                    opacity: 1;
                    transition: all 0.8s 0.2s $ease-out;

                    .show-more {
                        transform: translateX(0);
                        opacity: 1;
                        transition: all 0.8s 0.2s $ease-out;
                    }
                }

                .audio-container {
                    opacity: 1;
                    transition: all 0.8s 0.2s $ease-out;

                    .control-container {
                        opacity: 1;
                        transform: translateX(0);
                        transition: all 0.8s 0.2s $ease-out;
                    }
                }
            }
        }

        .bottle-image-container {
            transition: opacity 0.7s $ease-out, transform 0.1ms 0.6s $ease-out;
            opacity: 0;
            transform: scale(0.85);
            transform-origin: left;
        }

        .bottle-image {
            height: 840px;

            transform: translateX(-50%);
            @media only screen and (max-width: 1439px) and (min-width: 768px) {
                height: 615px;
            }
            @media only screen and (max-width: 767px) {
                transform: unset;
                height: 363px;
                margin: 0 auto;
                display: block;
            }
        }

        .wine-about {
            margin-top: 143px;
            width: auto;
            transform: translateY(7%) translateX(-100px);
            transition: all 0.8s $ease-out;
            margin-left: 47px;
            @media only screen and (max-width: 1440px) and (min-width: 768px) {
                transform: translateY(7%) translateX(-60px);
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                margin-left: 0;
            }
            @media only screen and (max-width: 767px) {
                transform: unset;
                margin-top: 34px;
            }
            @media (max-width: 767px) {
                margin-left: 0px;
            }

            .brand {
                font-size: 55px;
                color: black;
                font-weight: normal;
                opacity: 0;
                transition: all 0.8s $ease-out;
                @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                    font-size: 55px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 768px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }
            }

            .name {
                margin-top: 10px;
                font-size: 34px;
                font-weight: normal;
                color: var(--brand-color);
                opacity: 0;
                transition: all 0.8s $ease-out;
                @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 1365px) {
                    font-size: 21px;
                }
            }

            .section-name {
                font-size: 16px;
                font-weight: bold;
                color: var(--brand-color);
            }

            .title {
                font-size: 16px;
                letter-spacing: 1.3px;
                color: var(--brand-color);

                &.description {
                    color: #0a141c;
                }

                @media only screen and (max-width: 1023px) {
                    font-size: 14px;
                }
            }

            .description-container {
                margin-top: 10px;
                opacity: 0;
                transition: all 0.8s 0.2s $ease-out;

                .description-list {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    overflow: hidden;
                    transition: max-height 0.6s $ease-out;
                    @media only screen and (max-width: 1023px) {
                        margin-top: 15px;
                    }

                    .list-item {
                        margin-top: 13px;

                        &:first-child {
                            margin-top: 0;
                        }

                        .list-content {
                            display: flex;
                            flex-direction: column;
                            grid-gap: 5px;
                            margin-top: 13px;
                            max-width: 450px;
                            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                                max-width: 380px;
                            }

                            span,
                            p {
                                color: var(--brand-color);
                                font-family: "Noto-Sans", "Noto-Sans-georgian";
                            }
                        }
                    }
                }

                .show-more {
                    text-decoration: underline;
                    color: black;
                    font-weight: bold;
                    cursor: pointer;
                    margin-top: 15px;
                    transform: translateX(5%);
                    opacity: 0;
                    transition: all 0.8s $ease-out;
                }
            }

            .audio-container {
                margin-top: 26px;
                opacity: 0;
                transition: all 0.8s 0.1s $ease-out;

                .music-about-content {
                    margin-top: 20px;
                }

                .title {
                    display: block;
                    max-width: 550px;

                    &:last-child {
                        margin-top: 5px;
                    }
                }

                #waveform {
                    margin-top: 20px;
                }

                .control-container {
                    display: flex;
                    align-items: center;
                    margin-top: 30px;
                    transform: translateX(5%);
                    transition: all 0.8s $ease-out;
                    opacity: 0;

                    .control {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        opacity: 0.5;
                        transition: opacity 0.3s ease;

                        &.active {
                            opacity: 1;
                        }

                        &.pause-container {
                            margin-left: 35px;
                        }

                        .title {
                            letter-spacing: 2px;
                            color: #0a141c;
                            margin-top: 0;
                            display: block;
                            margin-right: 8.5px;
                        }
                    }
                }
            }
        }
    }

    .out-cont {
        position: absolute;
        top: 50%;
        width: fit-content;
        height: 100%;
        left: 0;
        pointer-events: none;
        z-index: 2;
        cursor: pointer;
        will-change: transform;
        transition: transform 0.1ms 0.6s $ease-out;

        &.prev-content {
            transform: translate(-50%, -50%);

            .image-main,
            .clone {
                left: 0;
                transform: translateX(-50%) translateY(-298px);
                @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                    transform: translateX(-50%) translateY(-225px);
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    transform: translateX(-50%) translateY(-200px);
                }
                @media only screen and (max-width: 1023px) {
                    transform: translateX(-50%) translateY(-125px);
                }
            }
        }

        &.next-content {
            transform: translate(50%, -50%);
            right: 0;
            left: unset;

            .image-main,
            .clone {
                right: 0;
                transform: translateX(50%) translateY(-270px);
                @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                    transform: translateX(50%) translateY(-205px);
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    transform: translateX(50%) translateY(-185px);
                }
                @media only screen and (max-width: 1023px) {
                    transform: translateX(50%) translateY(-112px);
                }
            }
        }

        .image-main {
            height: 540px;
            position: relative;
            top: 458px;
            @media only screen and (max-width: 1440px) and (min-width: 768px) {
                top: 341px;
            }
            @media only screen and (max-width: 767px) {
                top: 181.5px;
            }
            @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                height: 410px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                height: 370px;
            }
            @media only screen and (max-width: 1023px) {
                height: 225px;
            }
        }

        .image-main {
            opacity: 0;
            pointer-events: auto;
            transition: opacity 0.8s $ease-out;
        }
    }
}
</style>
