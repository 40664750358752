import { render, staticRenderFns } from "./BrandWineCollections.vue?vue&type=template&id=681b8226&scoped=true&"
import script from "./BrandWineCollections.vue?vue&type=script&lang=js&"
export * from "./BrandWineCollections.vue?vue&type=script&lang=js&"
import style0 from "./BrandWineCollections.vue?vue&type=style&index=0&id=681b8226&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681b8226",
  null
  
)

export default component.exports